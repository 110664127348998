
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import DefaultLayout from "@/layout/DefaultLayout.vue";

const UserModule = namespace("user");

@Component({
  components: { DefaultLayout },
  mixins: [ViewMixin],
})
export default class UserView extends Vue {
  private name = "UserView";

  protected resource = "/user";
  protected descriptionField = "email";
  protected redirectRouteNameOnDeleteSuccess = "UserList";

  @UserModule.Action("del")
  protected removeAction: any;

  protected onAdd(): void {
    this.$router.push({ name: "UserCreate" });
  }
}
